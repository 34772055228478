import React, { useState, useRef, useEffect } from "react";
import UtilsService from "../../../services/Utils.service";
import {
  PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_HISTORICAL_PERFORMANCE_FILTER_OPTIONS,
  PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_HISTORICAL_PERFORMANCE_TITLE_LABEL,
  PRODUCT_DETAILS_PERFORMANCE_LAST_WEEK_KEY,
  PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_HISTORICAL_PRICE_TABLE_LABEL,
  PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_BID_PRICE_TITLE_LABEL,
  PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_BID_PRICE_LABELS,
  DOLLAR_SIGN, PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_ETF_PRICE_TABLE_LABEL,
  PRODUCT_DETAIL_PERFORMANCE_ANALYSIS_DISCLAIMER,
  PRODUCT_DETAILS_PERFORMANCE_MONTH_KEY,
  PRODUCT_DETAILS_PERFORMANCE_SIX_MONTH_KEY,
  PRODUCT_DETAILS_PERFORMANCE_YEAR_KEY,
  PRODUCT_DETAILS_PERFORMANCE_SINCE_ISSUE_DATE_KEY,
  PRODUCT_DETAIL_PERFORMANCE_ANALYSIS_DISCLAIMER_FR,
  PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_BID_PRICE_LABELS_WHEN_FEE_FALSE,
} from "../../../constants/ProductDetails.constants";
import MultiOptionsSingleSelectButtons from "../../misc/multi-options-single-select-buttons/MultiOptionsSingleSelectButtons.component";
import ModalComponent from "../../misc/modal/Modal.component";
import ProductDetailsHistoricalPriceTable from "./ProductDetailsHistoricalPriceTable.component";
import ProductDetailsHistoricalPerformanceChartComponent from "./ProductDetailsHistoricalPerformanceChart.component";
import TranslateComponent from "../../misc/translate/Translate.component";
import { PERFORMANCE_TIME_SERIES, PERFORMANCE_TYPE } from "../../../constants/Notes.constants";
import moment from "moment";
import NoteService from "../../../services/Note.service";
import ProductDetailsETFTable from "./ProductDetailsETFTable.component";
import Note from "../../../utils/Note";
import KeyValueTableComponent from "../../misc/key-value-table/KeyValueTable.component"; 
import LanguageService from "../../../services/Language.service";
import { SortByDate } from "../../../utils/sorters";
import { LANGUAGE_ENGLISH } from "../../../constants/Misc.constants";
import { toTDSDate } from "../../../services/Date.service";
import { convertToCurrency } from "../../../utils/valueUtils";

const ProductDetailsPerformanceAnalysisComponent = (props) => {
  const noteDetails = props.data;
  const [historicalFilterSelection, setHistoricalFilterSelection] = useState(PRODUCT_DETAILS_PERFORMANCE_SINCE_ISSUE_DATE_KEY);
  const [historicalPerformanceChartData, setHistoricalPerformanceChartData] = useState();
  const [historicalPerformanceData, setHistoricalPerformanceData] = useState();
  const [etfData, setETFData] = useState();
  const [isEtfData, setIsETFData] = useState(false);
  const [bidPriceData, setBidPriceData] = useState();
  const [mostRecentBidPriceDate, setMostRecentBidPriceDate] = useState();
  const [isFee, setIsFee] = useState(false);

  const historicalPriceTableModalRef = useRef();
  const etfScheduleTableModalRef = useRef();

  const handleClickOnHistoricalPriceTable = () => {
    historicalPriceTableModalRef.current.showModal();
  };

  const handleClickOnETFScheduleTable = () => {
    etfScheduleTableModalRef.current.showModal();
  };

  const handleHistoricalPerformanceFilterSelection = (id) => {
    setHistoricalFilterSelection(id);
  };

  useEffect(() => {
    setIsFee(noteDetails?.isFee);
    const getBidPriceSectionData = () => {
      const closingBid = Note.getBidPriceByMostRecentDate(noteDetails);
      if(closingBid) {
        setMostRecentBidPriceDate( toTDSDate(closingBid.reportDate));
      }
      const currentETF = getCurrentETF();
      const etfEndDate = getETFEndDate() !== null ? getETFEndDate() : '';
      const bidPriceLessETF = closingBid ? ((closingBid.value) - (currentETF*100)) : '';
      setBidPriceData({
        closingBidPrice: closingBid ? convertToCurrency(closingBid.value, 2) : '',
        currentETF: currentETF ? convertToCurrency(currentETF*100, 2) : '',
        bidPriceLessETF: bidPriceLessETF ? convertToCurrency(bidPriceLessETF, 2) : '',
        ETFEndDate: etfEndDate ? toTDSDate(etfEndDate) : '',
        yearHigh: getHighestAndLowestBidPrice(true),
        yearLow: getHighestAndLowestBidPrice(false),
      });
    }

    const getHistoricalPerformanceData = () => {
      setHistoricalPerformanceData(NoteService.getHistoricalPerformanceData(noteDetails)
      .sort(SortByDate.sortDesc('date')));      
    }

    const getEarlyTradingFeeData = () => {
      const etfData = NoteService.getEarlyTradingFeeData(noteDetails);    
      if (isFee && etfData !== null && etfData.length > 0) {
        setIsETFData(true);

        //add ETF end date with Value "$0", W000OPSN-396
        etfData.push(
        {
          "from": toTDSDate(getETFEndDate()),
           "until": "",
           "etfPerNote": "0.00"
        }
        );
      }

      setETFData(etfData.sort(SortByDate.sortAsc('from')));
    }

    const getHighestAndLowestBidPrice = (isHighest) => {
      const previousYearStartDate = moment(moment(moment.now()).subtract(52, 'W'));
      if (noteDetails?.performanceTimeseries?.length > 0) {
        const bidPrices = noteDetails.performanceTimeseries.filter(e =>
          e.performanceType === PERFORMANCE_TYPE.BID_PRICE
          && (moment(e.reportDate) > previousYearStartDate
            && moment(e.reportDate) < moment.now()));
        if (bidPrices.length > 0) {
          
          if (isHighest) {
            const highestBid = bidPrices.sort(comparePerfTimeSeriesForHighestValue)[0];
            return highestBid ? DOLLAR_SIGN + Note.converToDecimal(highestBid.value)
              + ' (' +  toTDSDate(highestBid.reportDate) + ')' : '';
          } else {
            const lowestBid = bidPrices.sort(comparePerfTimeSeriesForLowestValue)[0];
            return lowestBid ? DOLLAR_SIGN + Note.converToDecimal(lowestBid.value)
              + ' (' +  toTDSDate(lowestBid.reportDate) + ')' : '';
          }
        }
      }
      return null;
    }

    const getETFEndDate = () => {
      if (noteDetails?.earlyTradingFee && noteDetails?.earlyTradingFee.length > 0) {
          const etfEndDate = noteDetails?.earlyTradingFee.sort(SortByDate.sortDesc('endDate'))[0];

          //plus one day etf end date, W000OPSN-396
          return moment(etfEndDate.endDate).add(1, "d").utc().format();
      }
      return null;
    }

    const getCurrentETF = () => {
      if (noteDetails?.performanceTimeseries?.length > 0) {
        const earlyTradingFee = noteDetails.performanceTimeseries.filter(e =>
          e.performanceType === PERFORMANCE_TYPE.EARLY_TRADING_FEE);
        if (earlyTradingFee?.length > 0) {
          const latestEarlyTradingFee = earlyTradingFee.sort(SortByDate.sortDesc('reportDate'))[0];
          return latestEarlyTradingFee ? latestEarlyTradingFee.value : '';
        }
      }
      return '';
    }

    const getHistoricalPerformanceChartData = () => {
      const bidPrice = [];
      const underlyingValue = [];
      const perfSeriesBidPriceData = noteDetails?.performanceTimeseries?.filter(perfTimeSeries =>
        (perfTimeSeries.performanceType === PERFORMANCE_TYPE.BID_PRICE
          && perfTimeSeries.underlyingReference === PERFORMANCE_TIME_SERIES.UNDERLYING_REFERENCE));

      const perfSeriesUnderlyingValueData = noteDetails?.performanceTimeseries?.filter(perfTimeSeries =>
        (perfTimeSeries.performanceType === PERFORMANCE_TYPE.ASSET_LEVEL
          && perfTimeSeries.underlyingReference === PERFORMANCE_TIME_SERIES.UNDERLYING_REFERENCE));

      if (perfSeriesBidPriceData) {

        perfSeriesBidPriceData.forEach(bidPriceData => {
          bidPrice.push(
            {
              'date':  toTDSDate(bidPriceData.reportDate),
              'price': '' + bidPriceData.value
            }
          );
        });
      }
      if (perfSeriesUnderlyingValueData) {
        perfSeriesUnderlyingValueData.forEach(underlyingValueData => {
          underlyingValue.push({
            'date':  toTDSDate(underlyingValueData.reportDate),
            'price': '' + underlyingValueData.value
          });
        });
      }
      
      switch (historicalFilterSelection) {
        case PRODUCT_DETAILS_PERFORMANCE_LAST_WEEK_KEY:
          
          const firstdayOfTheWeek = moment().subtract(1, 'weeks').startOf('isoWeek');
          const lastdayOfTheWeek = moment().subtract(1, 'weeks').endOf('isoWeek');
          setHistoricalPerformanceChartData({
            bidPrice: bidPrice.filter((b) => (moment(b.date).isSameOrAfter(moment(firstdayOfTheWeek))
                && moment(b.date).isSameOrBefore(moment(lastdayOfTheWeek)))),
            underlyingValue: underlyingValue.filter((b) => (moment(b.date).isSameOrAfter(moment(firstdayOfTheWeek))
            && moment(b.date).isSameOrBefore(moment(lastdayOfTheWeek)))),
          });
          break;
          case PRODUCT_DETAILS_PERFORMANCE_MONTH_KEY:
            const oneMonthBackDate = moment().subtract(1, 'month');
            setHistoricalPerformanceChartData({
              bidPrice: bidPrice.filter((b) => (moment(b.date).isSameOrBefore(moment()) 
                  && moment(b.date).isSameOrAfter(moment(oneMonthBackDate)))),
              underlyingValue: underlyingValue.filter((b) => (moment(b.date).isSameOrBefore(moment()) 
              && moment(b.date).isSameOrAfter(moment(oneMonthBackDate)))),
            });
          break;
          case PRODUCT_DETAILS_PERFORMANCE_SIX_MONTH_KEY:
            const sixMonthBackDate = moment().subtract(6, 'months');
            setHistoricalPerformanceChartData({
              bidPrice: bidPrice.filter((b) => (moment(b.date).isSameOrBefore(moment()) 
                  && moment(b.date).isSameOrAfter(moment(sixMonthBackDate)))),
              underlyingValue: underlyingValue.filter((b) => (moment(b.date).isSameOrBefore(moment()) 
              && moment(b.date).isSameOrAfter(moment(sixMonthBackDate)))),
            });
          break;
          case PRODUCT_DETAILS_PERFORMANCE_YEAR_KEY:
            const oneYearBackDate = moment().subtract(1, 'year');
            setHistoricalPerformanceChartData({
              bidPrice: bidPrice.filter((b) => (moment(b.date).isSameOrBefore(moment()) 
                  && moment(b.date).isSameOrAfter(moment(oneYearBackDate)))),
              underlyingValue: underlyingValue.filter((b) => (moment(b.date).isSameOrBefore(moment()) 
              && moment(b.date).isSameOrAfter(moment(oneYearBackDate)))),
            });
          break;
          case PRODUCT_DETAILS_PERFORMANCE_SINCE_ISSUE_DATE_KEY:
            setHistoricalPerformanceChartData({
              bidPrice: bidPrice.filter((b) => (moment(b.date).isSameOrBefore(moment())
                  && moment(b.date).isSameOrAfter(moment(noteDetails?.issueDate)))),
              underlyingValue: underlyingValue.filter((b) => (moment(b.date).isSameOrBefore(moment())
              && moment(b.date).isSameOrAfter(moment(noteDetails?.issueDate)))),
            });
          break;
        default:
          setHistoricalPerformanceChartData({
            bidPrice: bidPrice,
            underlyingValue: underlyingValue
        });
      }
    }
    
    const comparePerfTimeSeriesForHighestValue = (a, b) => {
      if (a.value < b.value) {
        return 1;
      }
      if (a.value > b.value) {
        return -1;
      }
      return 0;
    }

    const comparePerfTimeSeriesForLowestValue = (a, b) => {
      if (a.value < b.value) {
        return -1;
      }
      if (a.value > b.value) {
        return 1;
      }
      return 0;
    }

    getBidPriceSectionData();
    getHistoricalPerformanceChartData();
    getHistoricalPerformanceData();
    getEarlyTradingFeeData();
  }, [historicalFilterSelection, noteDetails, isFee]);
  
  const bidPrice = UtilsService.mapLabelsToValues(
    isFee ? PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_BID_PRICE_LABELS : 
    PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_BID_PRICE_LABELS_WHEN_FEE_FALSE,
    bidPriceData
  );

  const getBidPriceTitleWithDate = () => {
    let bidPriceTitle = PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_BID_PRICE_TITLE_LABEL;
    bidPriceTitle = LanguageService.translate(bidPriceTitle).replace('$','').replace('{date}', mostRecentBidPriceDate ? mostRecentBidPriceDate : '');
    return bidPriceTitle;
  }

  return (
    <div className="tds-sn-product-details-performance-analysis-container">
      <div className="left-container">
        <div className="title-filter">
          <h3>
            <TranslateComponent label={PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_HISTORICAL_PERFORMANCE_TITLE_LABEL} />
          </h3>
          <div className="historical-performance-filters">
            <MultiOptionsSingleSelectButtons
              legend="Filter By"
              options={PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_HISTORICAL_PERFORMANCE_FILTER_OPTIONS}
              onSelection={handleHistoricalPerformanceFilterSelection}
              selection={historicalFilterSelection}
            />
          </div>
        </div>
        {historicalPerformanceChartData && ( 
          <span className="historical-performance-chart">
            <ProductDetailsHistoricalPerformanceChartComponent data={historicalPerformanceChartData} />
          </span>
        )}
      </div>
      <div className="right-container">

        <div className="current-performance-container">
          <h3>
            <TranslateComponent label={getBidPriceTitleWithDate()} />
          </h3>
          <>
            <KeyValueTableComponent list={bidPrice} contained={true} className="key-terms-table" />
          </>
        </div>
        <div className="day-over-day-price-change-container">
          <div className="historical-price-table">
            <button onClick={handleClickOnHistoricalPriceTable}>
              <TranslateComponent label={PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_HISTORICAL_PRICE_TABLE_LABEL} />
            </button>
          </div>
          {isEtfData &&
            <div className="historical-price-table">
              <button onClick={handleClickOnETFScheduleTable}>
                <TranslateComponent label={PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_ETF_PRICE_TABLE_LABEL} />
              </button>
            </div>
          }
        </div>
        <div className="current-performance-container">
        {LanguageService.isLanguage(LANGUAGE_ENGLISH) ? PRODUCT_DETAIL_PERFORMANCE_ANALYSIS_DISCLAIMER : 
            PRODUCT_DETAIL_PERFORMANCE_ANALYSIS_DISCLAIMER_FR}
        </div><br/>
      </div>
      <ModalComponent
        ref={historicalPriceTableModalRef}
        id={"historicalPriceTableModal"}
        className="historical-price-table-modal-content"
      >
        <ProductDetailsHistoricalPriceTable data={historicalPerformanceData} />
      </ModalComponent>
      <ModalComponent
        ref={etfScheduleTableModalRef}
        id={"etfScheduleTableModal"}
        className="historical-price-table-modal-content"
      >
        <ProductDetailsETFTable data={etfData} />
      </ModalComponent>
    </div>
  );
};

export default ProductDetailsPerformanceAnalysisComponent;