import DOMPurify from "dompurify";

export default class UtilsService {
  // create a list of object of key value pairs with keys taken
  // from the first object and value taken from the second object
  static mapLabelsToValues(objectWithLabels, objectWithValues) {
    return (
      objectWithLabels &&
      objectWithValues &&
      Object.keys(objectWithLabels).map((key) => ({
        key: objectWithLabels[key],
        value: objectWithValues[key],
      }))
    );
  }

  static createLabelValuesWithHideCondition(objectWithLabels, objectWithValues) {
    const dataArray = [];
    objectWithLabels &&
    objectWithValues &&
    Object.keys(objectWithLabels).forEach((key) => {
        if(objectWithValues[key]) {
          dataArray.push({
          key: objectWithLabels[key],
          value: objectWithValues[key],
        });
      }
    });
    return dataArray;
  }

  static stringComparator({ isAscending, field }) {
    return (a, b) => {
      if (isAscending) {
        return a[field] > b[field] ? 1 : a[field] < b[field] ? -1 : 0;
      }

      return a[field] > b[field] ? -1 : a[field] < b[field] ? 1 : 0;
    };
  }

  static sanitizeInnerHtml = (data) => {
    return {__html: DOMPurify.sanitize(data)}
  }

  static executeInnerHtml = (data) => {
    return <div className="inner-html" dangerouslySetInnerHTML={this.sanitizeInnerHtml(data)} />
  }
}

const executeInnerHtml = UtilsService.executeInnerHtml;
const sanitizeInnerHtml = UtilsService.sanitizeInnerHtml;
export {
  executeInnerHtml,
  sanitizeInnerHtml
};