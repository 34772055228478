export const BASE_PATH = process.env.PUBLIC_URL;

export const LINK_PATH_HOME = BASE_PATH + "/home";

export const LINK_PATH_CURRENT_OFFERINGS = BASE_PATH + "/current-offerings";

export const LINK_PATH_PREVIOUS_OFFERINGS = BASE_PATH + "/previous-offerings";

export const LINK_PATH_NOTE_DETAILS = BASE_PATH + "/detail";

export const LINK_PATH_EVENTS = BASE_PATH + "/events";

export const LINK_PATH_EVENTS_LIFECYCLE = LINK_PATH_EVENTS + "/lifecycle";

export const LINK_PATH_EVENTS_CORPORATE = LINK_PATH_EVENTS + "/corporate";

export const LINK_PATH_RESOURCES = BASE_PATH + "/resources";

export const LINK_PATH_CONTACT_US = BASE_PATH + "/contact-us";

export const LINK_PATH_SN_LEGAL_DISCLOSURE = BASE_PATH + "/sn-legal-disclosure";

export const LINK_PATH_INVESTORS_INFO = BASE_PATH + "/investors-info";

export const LINK_PATH_RESOURCES_INVESTOR_INFO = LINK_PATH_RESOURCES + "/investor-framework";

export const LINK_PATH_RESOURCES_PRODUCT_INFO = LINK_PATH_RESOURCES + "/product-info";

export const LINK_PATH_RESOURCES_FAQS = LINK_PATH_RESOURCES + "/faqs";

export const LINK_PATH_PREFERENCES = BASE_PATH + "/preferences";

export const LINK_TDSECURITIES = "https://www.tdsecurities.com/ca/en/home-page";
export const LINK_TDSECURITIES_PRIVACY =
  "https://www.tdsecurities.com/ca/en/privacy-security";
export const LINK_TDSECURITIES_PRIVACY_FR =
  "https://www.tdsecurities.com/ca/fr/privacy-security";
export const LINK_TDSECURITIES_LEGAL =
  "https://www.tdsecurities.com/ca/en/legal";
export const LINK_TDSECURITIES_ACCESIBILITY =
  "https://www.tdcanadatrust.com/customer-service/accessibility/accessibility-at-td/index.jsp";
export const LINK_VIDEO_SRC = "https://tdtube.td.com/embed/secure/iframe/entryId/1_7e7h6fxr/uiConfId/29268072/st/0";
