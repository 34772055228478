import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from "react-router-dom";
import { LINK_PATH_CONTACT_US, LINK_PATH_SN_LEGAL_DISCLOSURE, LINK_PATH_INVESTORS_INFO, LINK_PATH_PREFERENCES } from "../../constants/LinkPaths.constants";
import { LANGUAGE_ENGLISH, LANGUAGE_FRENCH } from '../../constants/Misc.constants';
import LanguageService, {tr} from '../../services/Language.service';

export default class FooterComponent extends React.Component {

    footerLinks = null;

    constructor(props) {
        super(props);
        this.state = {
            showTopButton: false
        };
        this.handleTopClick = this.handleTopClick.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }
    handleTopClick() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        this.setState(
            {
                showTopButton: false
            }
        );
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    handleScroll(event) {
        let footer = document.getElementsByTagName('footer')
        let footerTopOffset = footer[0].offsetTop
        if (window.scrollY === 0 && this.state.showTopButton === true) {
            this.setState({showTopButton: false});
        }
        else if (window.scrollY >= footerTopOffset - window.innerHeight
                 && this.state.showTopButton !== true) {
            this.setState({showTopButton: true});
        }
    }
    render() {
        return (
            <React.Fragment>
                <footer className="non-printable">
                    <div className="tds-footer-divider"></div>
                    <div className="tds-container">
                        <div className="tds-footer-contact-us">
                            <Link to={LINK_PATH_CONTACT_US}>
                                    <span className="tds-footer-contact-us-text"><FormattedMessage id="CONTACT_US"/></span>
                                    <span className="td-icon td-icon-rightCaret icon-small"></span>
                            </Link>
                        </div>
                        <div className="tds-footer-links">
                            <div className="main">
                                <div className="link">
                                    <Link to={LINK_PATH_INVESTORS_INFO}  targe="_blank">
                                            <span className="tds-footer-link"><FormattedMessage id="footer.investor.information"/></span>
                                    </Link><br/>
                                </div>
                                <div className="link">
                                    <a href={tr('footer.privacy.policy.link')}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="tds-footer-link">
                                        <FormattedMessage id="footer.privacy.policy"/>
                                    </a>
                                </div>
                                <div className="link">
                                    <a href={tr('footer.internet.security.link')}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="tds-footer-link">
                                        <FormattedMessage id="footer.internet.security"/>
                                    </a>
                                </div>
                                <div className="link">
                                    <a href={tr('footer.legal.link')}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="tds-footer-link">
                                        <FormattedMessage id="footer.legal"/>
                                    </a>
                                </div>
                                <div className="link">
                                    <Link to={`${LINK_PATH_SN_LEGAL_DISCLOSURE}`}
                                    title={tr('footer.legal.disclosure.structured.notes.link')}
                                    className="tds-footer-link">
                                        <FormattedMessage id="footer.legal.disclosure.structured.notes"/>
                                    </Link>
                                </div>
                                <div className="link">
                                    <Link to={`${LINK_PATH_PREFERENCES}`}
                                    title={tr('ad_choices')}
                                    className="tds-footer-link">
                                        <FormattedMessage id="ad_choices"/>
                                    </Link>
                                </div>
                                <div className="link">
                                    <a href={tr('footer.terms.of.use.link')}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="tds-footer-link">
                                        <FormattedMessage id="footer.terms.of.use"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="tds-footer-logos">
                            <a href={LanguageService.isLanguage(LANGUAGE_ENGLISH) ? "https://www.ciro.ca/" : "https://www.ocri.ca/"}
                                title={tr('footer.iroc.logo.title')}
                                target="_blank"
                                rel="noopener noreferrer"
                                nofollow={'true'}>
                                <img src={LanguageService.isLanguage(LANGUAGE_ENGLISH) ? "/assets/img/CIRO-Logo-Dark-Desktop-EN.png" : "/assets/img/CIRO-Logo-Dark-Desktop-FR.png"}
                                    alt={tr('footer.iroc.logo.title')} />
                            </a>
                            <a href={LanguageService.isLanguage(LANGUAGE_ENGLISH) ? "https://www.cipf.ca/" : "https://www.fcpi.ca/"}
                                title={tr('footer.cipf.logo.title')}
                                target="_blank"
                                rel="noopener noreferrer"
                                nofollow={'true'}>
                                <img src={LanguageService.isLanguage(LANGUAGE_ENGLISH) ? "/assets/img/CIPF-Logo-Colour-2024-EN.png" : "/assets/img/CIPF-Logo-Colour-2024-FR.png"}
                                    className="td-rte-margin-top-none"
                                    alt={tr('footer.cipf.logo.title')} />
                            </a>
                        </div>
                        {
                            LanguageService.isLanguage(LANGUAGE_ENGLISH) && 
                                <div className="tds-footer-note">
                                    TD Securities (USA) LLC is a member of <a href="https://www.sipc.org/" target="_blank" rel="noopener noreferrer" nofollow={'true'}>SIPC</a> and <a
                                        href="https://www.finra.org/" target="_blank" rel="noopener noreferrer" nofollow={'true'}>FINRA</a> (see FINRA <a
                                        href="https://brokercheck.finra.org/" target="_blank" rel="noopener noreferrer" nofollow={'true'}>BrokerCheck</a>).
                                </div>
                        }   
                         {
                            LanguageService.isLanguage(LANGUAGE_FRENCH) && 
                                <div className="tds-footer-note">
                                    TD Securities (USA) LLC est membre de la <a href='https://www.sipc.org/' target='_blank' rel='noopener noreferrer' nofollow={'true'} title='SIPC'>SIPC</a> et de la <a
                                        href='https://www.finra.org/' target='_blank' rel='noopener noreferrer' nofollow={'true'} title='FINRA'>FINRA</a> (voir l’outil <a
                                        href='https://brokercheck.finra.org/' target='_blank' rel='noopener noreferrer' nofollow={'true'} title='BrokerCheck'>BrokerCheck</a> de la FINRA).
                                </div>
                        }                    
                        {
                            this.state.showTopButton &&
                            <div className="tds-footer-top fixed" onClick={this.handleTopClick}>
                                <span className="td-icon td-icon-upCaret icon-regular"></span>
                                <span className="tds-footer-top-text"><FormattedMessage id="footer.top"/></span>
                            </div>
                        }
                    </div>
                </footer>                
            </React.Fragment>
        )
    }
}
