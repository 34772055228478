import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DIRECTOR_F, DIRECTOR_M } from '../../../constants/Misc.constants';
import TranslateComponent from '../../misc/translate/Translate.component';

export default class ContactUsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = { };
    }
    render() {
        return (
            <div className="tds-container">
                <h2><FormattedMessage id="contact.us.title"/></h2>
                <div className="tds-contact-us-container">
                    <h2><FormattedMessage id="contact.us.public.inquiry"/></h2>
                    <p><FormattedMessage id="contact.us.public.inquiry.desc"/></p>   
                    <h2><FormattedMessage id="contact.us.investment.professional.inquiry"/></h2>               
                    <div className="tds-contacts-container">                  
                        <div className="tds-contacts-box">
                            <h2><FormattedMessage id="contact.us.western.canada.sales"/></h2>
                            <div className="tds-contact">                                
                                <p>Jiena Chen, <TranslateComponent label={DIRECTOR_F} /></p>                                
                                <div className="tds-sn-email-field">
                                    <span className="td-icon td-icon-email"></span>
                                    <span className="tds-sn-icon-text">Jiena.Chen@tdsecurities.com</span>
                                </div>
                            </div>
                        </div>                        
                        <div className="tds-contacts-box">
                            <h2><FormattedMessage id="contact.us.ontario.sales"/></h2>
                            <div className="tds-contact">                                
                                <p>Bill Dimitriou, <TranslateComponent label={DIRECTOR_M}/></p>                                
                                <div className="tds-sn-email-field">
                                    <span className="td-icon td-icon-email"></span>
                                    <span className="tds-sn-icon-text">Bill.Dimitriou@tdsecurities.com</span>
                                </div>
                            </div>
                            <div className="tds-contact">
                                <p>Eric Lalonde, VP</p>                                
                                <div className="tds-sn-email-field">
                                    <span className="td-icon td-icon-email"></span>
                                    <span className="tds-sn-icon-text">Eric.lalonde@tdsecurities.com</span>
                                </div>
                            </div>
                        </div>
                        <div className="tds-contacts-box">
                            <h2><FormattedMessage id="contact.us.quebec.eastern.canada.sales"/></h2>
                            <div className="tds-contact">                                
                                <p>Catherine Chouinard, <TranslateComponent label={DIRECTOR_F}/></p>                                
                                <div className="tds-sn-email-field">
                                    <span className="td-icon td-icon-email"></span>
                                    <span className="tds-sn-icon-text">Catherine.Chouinard@tdsecurities.com</span>
                                </div>
                            </div>
                            <div className="tds-contact">
                                <p>Hani Antar, VP</p>                                
                                <div className="tds-sn-email-field">
                                    <span className="td-icon td-icon-email"></span>
                                    <span className="tds-sn-icon-text">Hani.Antar@tdsecurities.com</span>
                                </div>
                            </div>
                        </div>
                        <div className="tds-contacts-box">
                            <h2><FormattedMessage id="contact.us.fundserv.dealer.support.line"/></h2>
                            <div className="tds-contact">                                
                                <p><FormattedMessage id="contact.us.fundserv.dealer.support.line.desc"/></p>
                                <div className="tds-sn-phone-field">
                                    <span className="td-icon td-icon-contactUs"></span>
                                    <span className="tds-sn-icon-text">1-877-877-8623</span>
                                </div>                                
                            </div>                            
                        </div>
                        <div className="tds-contacts-box">
                            <h2><FormattedMessage id="contact.us.investment.solutions.group"/></h2>
                            <div className="tds-contact">                                
                                <p><FormattedMessage id="contact.us.investment.solutions.group.desc"/></p>
                                <div className="tds-sn-email-field">
                                    <span className="td-icon td-icon-email"></span>
                                    <span className="tds-sn-icon-text">investmentsolutionsgroup@tdsecurities.com</span>
                                </div>                                
                            </div>                            
                        </div>
                        <div className="tds-contacts-box">
                            <h2><FormattedMessage id="contact.us.waterhouse.direct.investing.clients"/></h2>
                            <div className="tds-contact-inline">                                
                                <div className="tds-contact">                                
                                    <p><FormattedMessage id="contact.us.waterhouse.direct.investing.clients.lang1"/></p>
                                    <div className="tds-sn-phone-field">
                                        <span className="td-icon td-icon-contactUs"></span>
                                        <span className="tds-sn-icon-text">1-800-465-5463</span>
                                    </div>                                
                                </div>
                                <div className="tds-contact">                                
                                    <p><FormattedMessage id="contact.us.waterhouse.direct.investing.clients.lang2"/></p>
                                    <div className="tds-sn-phone-field">
                                        <span className="td-icon td-icon-contactUs"></span>
                                        <span className="tds-sn-icon-text">1-800-361-2684</span>
                                    </div>                                
                                </div>                               
                            </div>                            
                        </div>
                    </div>                  
                </div>
            </div>
        )
    }
}