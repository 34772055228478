import React, {useEffect, useState, useRef,} from "react";
import {Redirect, useParams, useHistory} from "react-router-dom";
import {
  AS_OF,
  PRODUCT_DETAILS_BACK_LABEL,
  PRODUCT_DETAILS_HEADER_CLOSING_BID_PRICE_LABEL,
  PRODUCT_DETAILS_ICON_PRINT_LABEL,
  PRODUCT_DETAILS_TAB_TITLE_ADDITIONAL_PERFORMANCE_DATA,
  PRODUCT_DETAILS_TAB_TITLE_DOCUMENTS,
  PRODUCT_DETAILS_TAB_TITLE_NOTE_EVENTS,
  PRODUCT_DETAILS_TAB_TITLE_OVERVIEW,
  PRODUCT_DETAILS_TAB_TITLE_PERFORMANCE_ANALYSIS,
  PRODUCT_DETAILS_TAB_TITLE_UNDERLYING_INTEREST,
} from "../../constants/ProductDetails.constants";
import NoteService from "../../services/Note.service";
import IconComponent from "../misc/icon/Icon.component";
import TabComponent from "../misc/tabs/Tab.component";
import TabContentComponent from "../misc/tabs/TabContent.component";
import TabsComponent from "../misc/tabs/Tabs.component";
import SpinnerComponent from "../spinner/Spinner.component";
import ProductDetailsOverviewComponent from "./overview/ProductDetailsOverview.component";
import ProductDetailsUnderlyingInterestComponent
  from "./underlying-interest/ProductDetailsUnderlyingInterest.component";
import {PRODUCT_DETAILS_PAGE_TITLE} from "../../constants/PageTitle.constants";
import TranslateComponent from "../misc/translate/Translate.component";
import LanguageService from "../../services/Language.service";
import {LANGUAGE_ENGLISH} from "../../constants/Misc.constants";
import IrnNoteDetailsComponent from "./irn/IrnNoteDetails.component";
import {ROUT_PATH_404_PAGE} from "../../constants/Routes.constants";
import ProductDetailsDocuments from "./documents/ProductDetailsDocuments.component";
import ProductDetailsAdditionalPerformance from "./additionalPerformance/ProductDetailsAdditionalPerformance.component";
import ProductDetailsNoteEventsComponent from "./note-events/ProductDetailsNoteEvents.component";
import ProductDetailsPerformanceAnalysisComponent from "./performance-analysis/ProductDetailsPerformanceAnalysis.component";
import ModalComponent from "../misc/modal/Modal.component";
import PrintOption from "./note-prints/PrintOption.component";
import Note from "../../utils/Note";
import { convertToCurrency } from "../../utils/valueUtils";
import { toTDSDate } from "../../services/Date.service";

const ProductDetailsPageComponent = (props) => {
  const [hasError, setHasError] = useState(false);
  const [tabSelection, setTabSelection] = useState(0);
  const [tabsConfig, setTabsConfig] = useState([]);
  const [noteDetails, setNoteDetails] = useState();
  const [loading, setLoading] = useState(true);
  const [isHistoricalNote, setIsHistoricalNote] = useState(false);
  const history = useHistory();
  let { code } = useParams();

  const language = LanguageService.currentLanguage;
  const printOptionModalRef = useRef();
  useEffect(() => {
    const fetchNoteDetails = async () => {
      try {
        const note = await NoteService.getNoteDetailsById(code);
        setNoteDetails(note);
        setLoading(false);
      } catch(err) {
        setHasError(true);
      }
    };
    fetchNoteDetails();
  }, [code, language]);

  useEffect(() => {
    document.title = PRODUCT_DETAILS_PAGE_TITLE;
  }, [])

  // Create tabs based on data received.
  // TODO: confirm with business whether tabs should be displayed while
  // data is being retrieved.
  useEffect(() => {

    const getTabsConfig = () => {
      const tabsConfig = [];
      setIsHistoricalNote(NoteService.isHistoricalNote(noteDetails));
      tabsConfig.push({
        tabTitle: PRODUCT_DETAILS_TAB_TITLE_OVERVIEW,
        tabContent: <ProductDetailsOverviewComponent data={noteDetails} />,
      });

      if(!NoteService.isHistoricalNote(noteDetails)) {
        if( noteDetails.underlyingInterests.length > 0 || noteDetails.performanceTimeseries?.length > 0) {
          tabsConfig.push( {
            tabTitle: PRODUCT_DETAILS_TAB_TITLE_UNDERLYING_INTEREST,
            tabContent: <ProductDetailsUnderlyingInterestComponent data={noteDetails} />,
          });
        }
        tabsConfig.push({
            tabTitle: PRODUCT_DETAILS_TAB_TITLE_NOTE_EVENTS,
            tabContent: <ProductDetailsNoteEventsComponent data={noteDetails} />,
        });
         // Future tabs to add as we work thorugh each tabs
         tabsConfig.push({
          tabTitle: PRODUCT_DETAILS_TAB_TITLE_PERFORMANCE_ANALYSIS,
          tabContent: <ProductDetailsPerformanceAnalysisComponent data={noteDetails} />,
        });
      }

      if (NoteService.isHistoricalNote(noteDetails)) {
        tabsConfig.push({
          tabTitle: PRODUCT_DETAILS_TAB_TITLE_ADDITIONAL_PERFORMANCE_DATA,
          tabContent: <ProductDetailsAdditionalPerformance data={noteDetails}/>,
        });
      }

      tabsConfig.push({
        tabTitle: PRODUCT_DETAILS_TAB_TITLE_DOCUMENTS,
        tabContent: <ProductDetailsDocuments data={noteDetails}/>,
      });

      return tabsConfig;
    }

    if (noteDetails) {
      setTabsConfig(getTabsConfig());
    }
  }, [noteDetails]);

  const showNotePrintOptionModal = () => {
    printOptionModalRef.current.showModal();
  }

  const handleClickBackButton = (e) => {
    e.preventDefault();
    history.goBackFlag = true;
    history.goBack();
  };

  const showSpinner = () => loading && <SpinnerComponent />;

  // TODO: add error condition in the integration story
  const resultFetched = () => !loading;

  const getNoteName = () => !loading && (LanguageService.currentLanguage === LANGUAGE_ENGLISH ? noteDetails.longName : noteDetails.longNameFr);

  const closingBidPrice = !loading && Note.getBidPriceByMostRecentDate(noteDetails);

  const redirectTo404 = () => hasError && <Redirect to={ROUT_PATH_404_PAGE} />

  const showIrnDetails = () => Note.isIrnNote(noteDetails) && <IrnNoteDetailsComponent data={noteDetails}/>;

  const showNonIrnDetails = () => (!Note.isIrnNote(noteDetails)) &&
    <>
      <TabsComponent value={tabSelection} onChange={(idx) => setTabSelection(idx)}>
        {tabsConfig.map(({ tabTitle }, idx) => (
          <TabComponent key={idx}><TranslateComponent label={tabTitle} /></TabComponent>
        ))}
      </TabsComponent>
      <div className="tds-container">
        <div className="tds-sn-product-details-tab-content-container">
          {tabsConfig.map(({ tabContent }, idx) => (
            <TabContentComponent key={idx} value={tabSelection} index={idx}>
              {tabContent}
            </TabContentComponent>
          ))}
        </div>
      </div>
    </>

  return (
    <div className="tds-sn-product-details-container  non-printable">
      <div className="tds-container">
        <button className="tds-sn-product-details-back-button" onClick={handleClickBackButton}>
          &lt; <TranslateComponent label={PRODUCT_DETAILS_BACK_LABEL} />
        </button>
        <div className="tds-sn-product-details-title-row">
          <h1 className="tds-sn-product-details-title">{getNoteName()}</h1>
          { closingBidPrice &&
             <h1 className="tds-sn-product-details-closing-bid-price"><TranslateComponent label={PRODUCT_DETAILS_HEADER_CLOSING_BID_PRICE_LABEL} />
              :<span className="tds-sn-product-details-closing-bid-price value">{convertToCurrency(closingBidPrice.value, 2)}</span><br/><span className="tds-sn-product-details-closing-bid-price date-font-size">(<TranslateComponent label={AS_OF} /> {toTDSDate(closingBidPrice.reportDate)})</span></h1>
          }
          <div className="tds-sn-product-details-icons-container">
            <IconComponent name="printer" onClick={showNotePrintOptionModal}>
              <TranslateComponent label={PRODUCT_DETAILS_ICON_PRINT_LABEL} />
            </IconComponent>
            {/*<IconComponent name="email">
<MailtoComponent email={""} text={LanguageService.translate(PRODUCT_DETAILS_ICON_EMAIL_LABEL)} />
</IconComponent>*/}
          </div>
        </div>
      </div>
      {showSpinner()}
      {redirectTo404()}
      {resultFetched() && showIrnDetails()}
      {resultFetched() && showNonIrnDetails()}
      <ModalComponent
          ref={printOptionModalRef}
          id={'printOptionModal'}
          className="print-options-modal-content"
        >
          <PrintOption code={code} isIrnNote={Note.isIrnNote(noteDetails)} isHistoricalNote = {isHistoricalNote} note={noteDetails}/>
        </ModalComponent>
    </div>
    
  );
 
};
export default ProductDetailsPageComponent;