export const ROUTE_BASE = process.env.PUBLIC_URL;

export const ROUTE_PATH_HOME = ROUTE_BASE + "/home";

export const ROUTE_PATH_SEARCH = ROUTE_BASE + "/search";

export const ROUTE_PATH_CURRENT_OFFERINGS = ROUTE_BASE + "/current-offerings";

export const ROUTE_PATH_PREVIOUS_OFFERINGS = ROUTE_BASE + "/previous-offerings";

export const ROUTE_PATH_NOTE_DETAILS = ROUTE_BASE + "/detail/:code";

export const ROUTE_PATH_EVENTS = ROUTE_BASE + "/events";

export const ROUTE_PATH_EVENTS_LIFECYCLE = ROUTE_PATH_EVENTS + "/lifecycle/:id";

export const ROUTE_PATH_EVENTS_CORPORATE = ROUTE_PATH_EVENTS + "/corporate/:id";

export const ROUTE_PATH_CONTACT_US = ROUTE_BASE + "/contact-us";

export const ROUTE_PATH_SN_LEGAL_DISCLOSURE = ROUTE_BASE + "/sn-legal-disclosure";

export const ROUTE_PATH_INVESTORS_INFO = ROUTE_BASE + "/investors-info";

export const ROUTE_PATH_RESOURCES = ROUTE_BASE + "/resources";

export const ROUTE_PATH_RESOURCES_INVESTOR_FRAMEWORK = ROUTE_PATH_RESOURCES + "/investor-framework";

export const ROUTE_PATH_RESOURCES_PRODUCT_INFORMATION = ROUTE_PATH_RESOURCES + "/product-info";

export const ROUTE_PATH_RESOURCES_FAQS = ROUTE_PATH_RESOURCES + "/faqs";

export const ROUTE_PATH_PRINT = ROUTE_BASE + "/print-comp/:code/:checkedOptions";

export const ROUT_PATH_404_PAGE = ROUTE_BASE + "/404";

export const ROUTE_PATH_PREFERENCES = ROUTE_BASE + "/preferences";