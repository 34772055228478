import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import {
  EVENT_PAGE_BACK_BUTTON_LABEL,
  EVENT_PAGE_CORPORATE_EVENT_DETAILS_TABLE_COLUMNS,
} from "../../constants/EventPage.constants";
import { LINK_PATH_EVENTS, LINK_PATH_NOTE_DETAILS } from "../../constants/LinkPaths.constants";
import LanguageService from "../../services/Language.service";
import {chooseLang} from "../../services/Language.service";
import NoteService from "../../services/Note.service";
import TableComponent from "../misc/table/Table.component";
import TranslateComponent from "../misc/translate/Translate.component";
import SpinnerComponent from "../spinner/Spinner.component";

const CorporateEventDetailsPageComponent = () => {
  const history = useHistory();
  const { id } = useParams();

  const [title, setTitle] = useState();
  const [overview, setOverview] = useState();
  const [corporateEventsData, setCorporateEventsData] = useState();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const lang = LanguageService.currentLanguage;

  useEffect(() => {
    const fetchData = async () => {
      const data = await NoteService.getNoteCorporateEventById(id);
      setLoading(false);
      setData(data);
    };
    fetchData();
    
  }, [id]);

  useEffect(() => {
    if(data) {
      setTitle(chooseLang(data.headline, data.headlineFr));
      setOverview(chooseLang(data.description, data.descriptionFr));
      const structuredNotes = data.structuredNotes;
      structuredNotes.forEach(structuredNote => {
        structuredNote.dateOfEvent = data.eventDate;
      });
      setCorporateEventsData(structuredNotes);
    }    
  }, [data, lang]);

  const handleEventClick = (field, index) => {
    if (field === "longName" || field === "longNameFr") {
      history.push(`${LINK_PATH_NOTE_DETAILS}/${corporateEventsData[index].code}`);
    }
  };

  const handleClickBackButton = (e) => {
    e.preventDefault();
    history.goBackFlag = true;
    history.goBack();
  };

  const showSpinner = () => loading && <SpinnerComponent />;

  // TODO: add error condition in the integration story
  const resultFetched = () => !loading;

  return (
    <div className="tds-sn-corporate-event-details-container tds-container">
      <Link to={LINK_PATH_EVENTS} className="back-button" title="Back to Event Search" onClick={handleClickBackButton}>
        &lt; <TranslateComponent label={EVENT_PAGE_BACK_BUTTON_LABEL} />
      </Link>
      {showSpinner()}
      {resultFetched() && (
        <>
          <h1>{title}</h1>
          <section>
            <p>{overview}</p>
            <div className="table-wrapper">
              <TableComponent
                className="corporate-event-details-table"
                columns={new EVENT_PAGE_CORPORATE_EVENT_DETAILS_TABLE_COLUMNS().columns}
                data={corporateEventsData}
                onRowClick={handleEventClick}
              />
            </div>
          </section>
        </>
      )}
    </div>
  );
};

export default CorporateEventDetailsPageComponent;
