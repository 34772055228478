import React from 'react';

export default class ModalComponent extends React.Component {
    active = false;
    constructor(props) {
        super(props);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }
    showModal() {
        this.active = true;
        this.forceUpdate();
    }
    hideModal() {
        this.active = false;
        this.forceUpdate();      
    }
    render() {
        return (
            <div id={this.props.id}
                className={this.active ? "tds-modal active" : "tds-modal"}>
                <div className={'tds-modal-content ' + this.props.className}>
                    <span 
                        className="tds-modal-close td-icon td-icon-close td-interactive-icon"
                        onClick={this.hideModal}
                    ></span>
                    
                    {this.active &&
                        <>
                        {this.props.children}
                        </>
                    }
                </div>
            </div>
        )
    }
}
